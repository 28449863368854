<template>
  <vx-card>
    <div class="flex flex-col gap-4 px-4">
      <h4>Edit Credit Note</h4>
      <div class="flex flex-col w-full mt-6 gap-1">
        <span class="w-1/4">DN Claim No</span>
        <multiselect
          id="teritory"
          v-model="selectedDebitNote"
          :options="debitNoteOptions"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="120"
          :limit="3"
          placeholder="Find DN Claim No"
          track-by="id"
          label="code"
          class="w-2/3"
          @search-change="onDNSearchChange"
          @select="onDebitNoteSelected"
          :disabled="true"
        >
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">
                <span class="option__title">{{ props.option.code }}</span>
              </span>
            </div>
          </template></multiselect
        >
      </div>
      <div class="flex flex-col w-full gap-1">
        <span class="w-1/4">Value DN</span>
        <vs-input readonly class="w-2/3" v-model="dnValue" />
      </div>
      <div class="flex flex-col w-full gap-1">
        <span class="w-1/4">DN Date</span>
        <date-picker
          class="w-2/3"
          :inline="false"
          name="DN Date"
          placeholder="DN Date"
          disabled
          v-model="debitNoteDate"
        />
      </div>
      <div class="flex flex-col w-full gap-1">
        <span class="w-1/4">Remaining CN</span>
        <vs-input readonly class="w-2/3" v-model="dnRemaining" />
      </div>
      <vs-divider />
      <div v-for="(line, i) in lines" :key="i" class="flex flex-col">
        <vx-card>
          <div class="flex justify-between w-full gap-10 items-center">
            <div class="flex flex-col w-full gap-4">
              <div class="flex gap-4 w-full">
                <div class="flex flex-col gap-1 w-2/3" style="min-width: 200px">
                  <span>Operating Unit</span>
                  <multiselect
                    id="teritory"
                    :options="operatingUnitOptions[i]"
                    v-model="line.operatingUnit"
                    :multiple="false"
                    :allow-empty="true"
                    :group-select="false"
                    :max-height="120"
                    :limit="3"
                    :custom-label="queryOperatingUnit"
                    placeholder="Find Operating Unit"
                    track-by="ID"
                    class="w-full"
                    @input="onOperatingUnitChange($event, i)"
                    disabled
                  >
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          <span class="option__title"
                            >({{ props.option.Code }}),
                            {{ props.option.Name }}</span
                          >
                        </span>
                      </div>
                    </template>

                    <template slot="tag" slot-scope="props">
                      <div class="multiselect__tag">
                        <span>{{ props.option.Name }}</span>
                        <i
                          class="multiselect__tag-icon"
                          @click.prevent
                          @mousedown.prevent.stop="
                            props.remove(props.option, $event)
                          "
                        />
                      </div> </template
                  ></multiselect>
                </div>
                <div class="flex flex-col gap-1 w-2/3" style="min-width: 200px">
                  <span>Territory</span>
                  <multiselect
                    id="teritory"
                    :options="territoryOptions[i]"
                    v-model="line.territory"
                    :multiple="false"
                    :allow-empty="true"
                    :group-select="false"
                    :max-height="120"
                    :limit="3"
                    :custom-label="queryTerritory"
                    placeholder="Find Operating Unit"
                    track-by="ID"
                    class="w-full"
                    disabled
                  >
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          <span class="option__title"
                            >({{ props.option.TerritoryCode }}),
                            {{ props.option.TerritoryName }}</span
                          >
                        </span>
                      </div>
                    </template>

                    <template slot="tag" slot-scope="props">
                      <div class="multiselect__tag">
                        <span>{{ props.option.TerritoryName }}</span>
                        <i
                          class="multiselect__tag-icon"
                          @click.prevent
                          @mousedown.prevent.stop="
                            props.remove(props.option, $event)
                          "
                        />
                      </div> </template
                  ></multiselect>
                </div>
                <div class="flex flex-col gap-1 w-2/3" style="min-width: 200px">
                  <span>Customer</span>
                  <multiselect
                    id="customer"
                    :options="customerOptions[i]"
                    v-model="line.customer"
                    :multiple="false"
                    :allow-empty="true"
                    :group-select="false"
                    :max-height="120"
                    :limit="3"
                    :custom-label="queryCustomer"
                    label="name"
                    placeholder="Find Customer"
                    track-by="ID"
                    class="w-full"
                    @search-change="onCustomerSearchChange($event, i)"
                    :key="customerKey"
                    disabled
                  >
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          <span class="option__title"
                            >({{ props.option.code }}),
                            {{ props.option.name }}</span
                          >
                        </span>
                      </div>
                    </template>

                    <template slot="tag" slot-scope="props">
                      <div class="multiselect__tag">
                        <span>{{ props.option.name }}</span>
                        <i
                          class="multiselect__tag-icon"
                          @click.prevent
                          @mousedown.prevent.stop="
                            props.remove(props.option, $event)
                          "
                        />
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>

              <div class="flex gap-4 w-full">
                <div class="flex flex-col gap-1 w-2/3" style="min-width: 200px">
                  <span>Value</span>
                  <vs-input
                    v-model="line.value"
                    class="w-full"
                    @keyup="onLineValueChange(i)"
                    @blur="onValueLineBlur(i)"
                  />
                </div>
                <!-- <div class="flex flex-col gap-1 w-2/3" style="min-width: 200px">
                  <span>VAT</span>
                  <vs-input readonly v-model="line.vat" class="w-full" />
                </div> -->
                <div class="flex flex-col gap-1 w-2/3" style="min-width: 200px">
                  <span>Total</span>
                  <vs-input readonly v-model="line.total" class="w-full" />
                </div>
                <div class="flex flex-col gap-1 w-2/3" style="min-width: 200px">
                  <!-- <span>Total</span>
                  <vs-input readonly v-model="line.total" class="w-full" /> -->
                </div>
              </div>
            </div>

            <!-- <div class="flex gap-3">
            <vx-tooltip
              text="Remove"
              style="width: fit-content"
              v-if="lines.length > 1"
            >
              <vs-button
                icon="icon-minus"
                icon-pack="feather"
                type="filled"
                color="danger"
                size="small"
                @click="removeLine(i)"
              />
            </vx-tooltip>
            <vx-tooltip text="Add" style="width: fit-content">
              <vs-button
                icon="icon-plus"
                icon-pack="feather"
                type="filled"
                size="small"
                @click="addLines"
              />
            </vx-tooltip>
          </div> -->
          </div>
          <!-- <vs-popup
            title="Credit Note Code"
            button-close-hidden
            :active.sync="cnCodePopup"
            :closeOnBackdrop="false"
            ref="popup"
          >
            <div class="w-full flex flex-col">
              <div class="flex gap-3 items-center w-full">
                <vs-input v-model="createdCodes" class="w-full" readonly />
                <vs-button
                  iconPack="feather"
                  icon="icon-file"
                  @click="copyToClipboard"
                  type="border"
                  >Copy</vs-button
                >
              </div>
              <div class="mt-8">
                <vs-button color="danger" @click="close">Close</vs-button>
              </div>
            </div>
          </vs-popup> -->
        </vx-card>
      </div>
      <vs-divider />
      <div class="flex gap-3">
        <vs-button color="danger" @click="closeForm">Close</vs-button>
        <vs-button v-if="canCreate" @click="saveCreditNote(false)"
          >Save</vs-button
        >
        <vs-button v-if="canCreate" @click="saveCreditNote(true)"
          >Save & Release</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>

<script>
import debounce from "debounce";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    "date-picker": Datepicker,
  },
  watch: {
    selectedDebitNote() {
      this.dnValue = this.priceFormat(this.selectedDebitNote.value);
      this.calculateRemaining();
    },
    cnCodePopup() {
      if (!this.cnCodePopup && this.isCLoseWithButton) {
        this.isCLoseWithButton = false;
        this.cnCodePopup = false;
        this.navigateBack();
      } else {
        this.cnCodePopup = true;
      }
    },
  },
  data() {
    return {
      debitNoteOptions: [],
      debitNoteDebounce: null,
      selectedDebitNote: null,
      lines: [],
      operatingUnitOptions: [],
      territoryOptions: [],
      customerOptions: [],
      debounceControllers: [],
      controller: { controller: null },
      customerKey: 0,
      dnValue: 0,
      dnRemaining: 0,
      creditNote: {},
      isLoading: true,
      beforeValue: 0,
      // cnCodePopup: false,
      // createdCodes: "",
      // isCLoseWithButton: false,
    };
  },
  async mounted() {
    const permissions = [...this.$store.state.user.permissions];
    if (!permissions || permissions.length === 0) {
      await this.$store.dispatch("user/getPermissions", "credit-note");
    }
    const param = atob(this.$route.params.ID);
    const id = parseInt(param);
    await this.getCreditNote(id);
    this.beforeValue = this.creditNote.TotalValue;
    const line = {
      id: 1,
      territory: null,
      operatingUnit: null,
      customer: {},
      value: this.priceFormat(this.creditNote.DPP),
      vat: this.priceFormat(this.creditNote.TaxValue),
      total: this.priceFormat(this.creditNote.TotalValue),
    };

    this.lines.push(line);

    this.operatingUnitOptions = [
      [...this.$store.state.operatingUnit.filter.operatingUnit],
    ];

    this.territoryOptions = [
      [...this.$store.state.operatingUnit.filter.territory_single],
    ];

    this.customerOptions = [
      [...this.$store.state.customerFilter.singleCustomers],
    ];

    await this.getCustomerOptions(
      this.creditNote.CustomerCode,
      this.creditNote.TerritoryID,
      0
    );

    line.customer = this.customerOptions[0];
    line.operatingUnit = this.operatingUnitOptions[0].find(
      (ou) => ou.TerritoryID === this.creditNote.TerritoryID
    );
    line.territory = this.territoryOptions[0].find(
      (t) => t.TerritoryID === this.creditNote.TerritoryID
    );

    // this.dnRemaining = this.priceFormat(this.creditNote.Remai);
    await this.getDebitNoteOptions();
    this.selectedDebitNote = this.debitNoteOptions.find(
      (dn) => dn.id === this.creditNote.DebitNoteID
    );

    this.dnRemaining = this.priceFormat(this.selectedDebitNote.remaining_value);
    this.isLoading = false;
  },
  computed: {
    debitNoteDate() {
      return !this.selectedDebitNote ? null : this.selectedDebitNote.dn_date;
    },
    canCreate() {
      return this.$store.getters["user/hasPermissions"]("create");
    },
  },
  methods: {
    addLines() {
      const line = {
        territory: null,
        operatingUnit: null,
        customer: {},
        value: this.formatValue(0),
        vat: this.formatValue(0),
        total: this.formatValue(0),
      };
      this.lines.push(line);

      this.operatingUnitOptions.push([
        ...this.$store.state.operatingUnit.filter.operatingUnit,
      ]);

      this.territoryOptions.push([
        ...this.$store.state.operatingUnit.filter.territory_single,
      ]);

      this.customerOptions.push([
        ...this.$store.state.customerFilter.singleCustomers,
      ]);

      line.operatingUnit = this.operatingUnitOptions[this.lines.length - 1][0];
      this.debounceControllers.push(this.controller);
    },
    removeLine(index) {
      this.lines.splice(index, 1);
      this.operatingUnitOptions.splice(index, 1);
      this.territoryOptions.splice(index, 1);
      this.customerOptions.splice(index, 1);
      this.debounceControllers.splice(index, 1);
    },
    queryOperatingUnit({ Code, Name }) {
      if (!Code || !Name) {
        return "Select operating unit";
      } else if (Code === "All") {
        return `(${Code}), ${Name}`;
      }
      return `(${Code}), ${Name}`;
    },
    queryTerritory({ TerritoryCode, TerritoryName }) {
      if (!TerritoryCode || !TerritoryName) {
        return "Select territory";
      } else if (TerritoryCode === "All") {
        return `(${TerritoryCode}), ${TerritoryName}`;
      }
      return `(${TerritoryCode}), ${TerritoryName}`;
    },
    queryCustomer({ name, code }) {
      if (!name || !code) {
        return "Select customer";
      } else if (name === "All") {
        return `(${code}), ${name}`;
      }
      return `(${code}), ${name}`;
    },
    onOperatingUnitChange(value, i) {
      if (!value || Object.keys(value).length === 0 || value.ID === 0) {
        this.territoryOptions[i] = [
          ...this.$store.state.operatingUnit.filter.territory_single,
        ];
      } else {
        this.territoryOptions[i] = [
          ...this.$store.state.operatingUnit.filter.territory_single,
        ].filter((territory) => territory.TerritoryID === value.TerritoryID);
      }
      this.lines[i].territory = {};
    },
    async getCustomerOptions(search, territory_id = null, i) {
      try {
        this.$vs.loading();
        const response = await this.$http.get(
          "/api/v1/master/customer/options",
          {
            params: {
              search,
              territory_id,
            },
          }
        );

        if (response.code < 299) {
          const temp = [...this.customerOptions];
          temp[i] = response.data;
          this.customerOptions = temp;
        }

        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: "Failed to fetch customer options",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    onCustomerSearchChange(search, i) {
      if (this.debounceControllers[i].controller) {
        this.debounceControllers[i].controller.clear();
      }

      const territory_id =
        Object.keys(this.lines[i].territory).length > 0 &&
        this.lines[i].territory.TerritoryID !== 81 // not HO territory
          ? this.lines[i].territory.TerritoryID
          : null;

      this.debounceControllers[i].controller = debounce(async () => {
        await this.getCustomerOptions(search, territory_id, i);
      }, 500);

      this.debounceControllers[i].controller();
    },
    async getCreditNote(id) {
      try {
        this.$vs.loading();
        const resp = await this.$http.get(`/api/v1/credit-note/data/${id}`);
        if (resp.code < 299) {
          this.creditNote = resp.data.creditNote;
        }
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: "Failed to fetch credit note",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    async getDebitNoteOptions(search = "") {
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/debit-note/cn-creation", {
          params: {
            length: 20,
            page: 1,
            search: search,
            order: "desc",
            sort: "id",
            debit_note_id: this.creditNote.DebitNoteID,
            // territory_id: this.$userLogin.territory_id,
            // territory_area_id: this.$userLogin.territory_area_id,
          },
        });

        if (resp.code < 299) {
          this.debitNoteOptions = resp.data;
          this.selectedDebitNote = this.debitNoteOptions[0];
        }

        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
        this.$vs.notify({
          title: "Error",
          text: "Failed to fetch debit note options",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    onDNSearchChange(search) {
      if (this.debitNoteDebounce) {
        this.debitNoteDebounce.clear();
      }

      this.debitNoteDebounce = debounce(async () => {
        await this.getDebitNoteOptions(search);
      }, 500);

      this.debitNoteDebounce();
    },
    key() {
      return Math.random();
    },
    calculateRemaining() {
      if (this.isLoading) {
        return;
      }
      if (this.selectedDebitNote.value === undefined) {
        return;
      }
      const total = this.lines.reduce((acc, line) => {
        let value = parseFloat(line.total.replace(/,/g, ""));
        if (isNaN(value)) {
          value = 0;
        }
        return acc + value;
      }, 0);

      let diff = this.beforeValue - total;

      if (diff < 0) {
        diff = Math.abs(diff);
        this.dnRemaining = this.selectedDebitNote.remaining_value - diff;
      } else {
        this.dnRemaining = this.selectedDebitNote.remaining_value + diff;
      }
      this.dnRemaining = this.formatPriceWithMinus(this.dnRemaining);
    },
    onKeyUp(i) {
      this.lines[i].value = this.priceFormat(this.lines[i].value);
    },
    formatPriceWithMinus(amount) {
      const newAmount = amount
        .toString()
        .replace(/[^-.\d]/g, "")
        .toString();
      console.log(amount, newAmount);
      amount = parseFloat(newAmount).toFixed(2);

      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatValue(angka) {
      return angka
        .toString()
        .replace(/[^0-9.-]/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onLineValueChange(i) {
      if (this.isLoading) {
        return;
      }
      let temp = this.lines[i].value;
      let value = parseFloat(temp.replace(/[,]/g, ""));
      if (isNaN(value)) {
        value = 0;
      }
      // const tax = value * 0.11;
      // this.lines[i].vat = this.priceFormat(tax);
      // this.lines[i].total = this.priceFormat(value + tax);
      this.lines[i].total = this.priceFormat(value);
      this.lines[i].value = this.priceFormat(value);
      this.calculateRemaining();
    },
    closeForm() {
      this.$router.push({ name: "credit-note" });
    },
    validate() {
      let isValid = false;

      if (
        !this.selectedDebitNote ||
        Object.keys(this.selectedDebitNote).length === 0
      ) {
        this.$vs.notify({
          title: "Error",
          text: "Please select a debit note",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return isValid;
      }

      const dnVal = parseFloat(this.selectedDebitNote.value);
      if (isNaN(dnVal) || dnVal <= 0) {
        this.$vs.notify({
          title: "Error",
          text: "Invalid debit note value",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return isValid;
      }

      const dnRemaining = parseFloat(this.dnRemaining);
      if (isNaN(dnRemaining) || dnRemaining < 0) {
        this.$vs.notify({
          title: "Error",
          text: "Total credit note value exceeds debit note value",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return isValid;
      }

      for (let i = 0; i < this.lines.length; i++) {
        if (
          !this.lines[i].operatingUnit ||
          Object.keys(this.lines[i].operatingUnit).length === 0
        ) {
          this.$vs.notify({
            title: "Error",
            text: "Please select operating unit",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return isValid;
        }

        if (
          !this.lines[i].territory ||
          Object.keys(this.lines[i].territory).length === 0
        ) {
          this.$vs.notify({
            title: "Error",
            text: "Please select territory",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return isValid;
        }

        if (
          !this.lines[i].customer ||
          Object.keys(this.lines[i].customer).length === 0
        ) {
          this.$vs.notify({
            title: "Error",
            text: "Please select customer",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return isValid;
        }

        const value = parseFloat(this.lines[i].value.replace(/,/g, ""));
        if (isNaN(value) || value <= 0) {
          this.$vs.notify({
            title: "Error",
            text: "Please input a valid value",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return isValid;
        }

        // const vat = parseFloat(this.lines[i].vat.replace(/,/g, ""));
        // if (isNaN(vat) || vat < 0) {
        //   this.$vs.notify({
        //     title: "Error",
        //     text: "Please input a valid VAT",
        //     color: "danger",
        //     position: "top-right",
        //     iconPack: "feather",
        //     icon: "icon-x-circle",
        //   });
        //   return isValid;
        // }

        const total = parseFloat(this.lines[i].total.replace(/,/g, ""));
        if (isNaN(total) || total <= 0) {
          this.$vs.notify({
            title: "Error",
            text: "Please input a valid total",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return isValid;
        }

        if (this.lines.length > 1) {
          if (this.hasDuplicateValues(this.lines, "customer")) {
            this.$vs.notify({
              title: "Error",
              text: "Customer must be unique",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            return isValid;
          }
        }
      }

      isValid = true;
      return isValid;
    },

    hasDuplicateValues(arr, key) {
      const values = arr.map((item) => item[key]);
      const uniqueValues = new Set(values);
      return uniqueValues.size !== values.length;
    },

    saveCreditNote(isWithRelease = false) {
      if (!this.validate()) {
        return;
      }

      this.$vs.loading();

      const data = {
        id: this.creditNote.ID,
        value: parseFloat(this.lines[0].value.replace(/,/g, "")),
        vat: parseFloat(this.lines[0].vat.replace(/,/g, "")),
        total: parseFloat(this.lines[0].total.replace(/,/g, "")),
        is_with_released: isWithRelease,
      };

      this.$http
        .put("api/v1/credit-note/claim", data)
        .then((res) => {
          this.$vs.loading.close();
          if (res.code < 299) {
            this.$vs.notify({
              title: "Success",
              text: "Credit note edited successfully",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            this.navigateBack();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to edit credit note",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          console.log(e);
          this.$vs.notify({
            title: "Error",
            text: e.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    onValueLineBlur(i) {
      console.log("blur gess");
      this.lines[i].value = this.priceFormat(
        parseFloat(this.lines[i].value.replace(/,/g, ""))
      );
    },
    navigateBack() {
      this.$router.push({ name: "credit-note" });
    },
    close() {
      this.isCLoseWithButton = true;
      this.cnCodePopup = false;
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.createdCodes);
        this.$vs.notify({
          title: "Success",
          text: "Credit Note code has been copied to clipboard",
          color: "success",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check-circle",
        });
      } catch (e) {
        console.log(e);
        this.$vs.notify({
          title: "Error",
          text: "Failed to copy credit note code",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    onDebitNoteSelected() {
      if (!this.selectedDebitNote) {
        for (let i = 0; i < this.operatingUnitOptions.length; i++) {
          this.operatingUnitOptions[i] = [
            ...this.$store.state.operatingUnit.filter.operatingUnit,
          ];
        }
      } else {
        for (let i = 0; i < this.operatingUnitOptions.length; i++) {
          this.operatingUnitOptions[i] = [
            ...this.$store.state.operatingUnit.filter.operatingUnit,
          ];

          this.operatingUnitOptions[i] = this.operatingUnitOptions[i].filter(
            (territory) =>
              territory.TerritoryID === this.selectedDebitNote.territory_id
          );

          this.lines[i].operatingUnit = null;
          this.lines[i].territory = null;
          this.lines[i].customer = null;
        }
      }
    },
  },
  //   computed: {
  //     getOperatingOptions() {
  //       const ou = [...this.$store.state.operatingUnit.filter.operatingUnit];
  //       ou.shift();
  //       return ou;
  //     },
  //     getTerritoryOptions() {
  //       const territory = [
  //         ...this.$store.state.operatingUnit.filter.territory_single,
  //       ];
  //       return territory;
  //     },
  //     getCustomerOptions() {
  //       const customer = [...this.$store.state.customerFilter.singleCustomers];
  //       return customer;
  //     },
  //   },
};
</script>
